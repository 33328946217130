import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import { AnForChannel } from "../../contents/AnForChannel";

const AddRollsPage = (props) => {
  return (
    <Layout location={props.location}>
      <Seo
        className="scroll license-bg ana-bg"
        title="Аналитическая платформа DUQQY"
      />
      <AnForChannel />
    </Layout>
  );
};

export default AddRollsPage;
